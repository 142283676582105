@use '@angular/material' as mat;
@import "_constants.scss";
$typography-configuration: mat.define-legacy-typography-config(
    $body-1: mat.define-typography-level(16px, 1.5),
    $font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($typography-configuration);`
@include mat.all-legacy-component-typographies($typography-configuration);
@include mat.legacy-core();

body {
    color: $darkgray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$primary: map-get($my-app-theme, primary);

a {
    text-decoration: none;
    color: mat.get-color-from-palette($primary);
    &:hover {
        color: #1a1f36
    }
}

strong {
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $deepgray;
}

h1 {
    @include font-size(24);
    font-weight: 500;
    display: inline-block;
    margin: 8px 0px;

    @media screen and (max-width: 600px) {
        margin-top: 0;
    }
}

h2 {
    @include font-size(22);
    font-weight: 500;
    margin: 32px 0px 24px;
    clear: both;
}

h3 {
    @include font-size(20);
    font-weight: 400;
    margin: 24px 0px 12px;
    clear: both;
}

h4 {
    @include font-size(18);
    font-weight: 400;
    margin: 8px 0px;
    clear: both;
}

h5 {
    @include font-size(16);
    font-weight: 500;
    margin: 8px 0px;
    clear: both;
}

h6 {
    @include font-size(16);
    font-weight: 500;
    color: $mediumgray;
    margin: 8px 0px;
    clear: both;
}

h2,
h3,
h4,
h5,
h6 {
    a {
        font-size: inherit;
    }

    @media screen and (max-width: 600px) {
        margin: 8px 0;
    }
}

.mat-tab-body-wrapper h2 {
    margin-top: 0;
}

p {
    margin: 14px 0 0;
}

p + ul {
    margin-top: 4px;
}

ol {
    li,
    p {
        margin: 4px 0;
    }
}

ol ol {
    list-style-type: lower-alpha;

    ol {
        list-style-type: lower-roman;
    }
}

li {
    padding-bottom: 8px;

    p {
        margin: 0;
    }
}

.sidenav-content a {
    color: $blue;
    &:hover {
        color: $mediumgray;
    }
}

.center {
    text-align: center !important;
}

.centre {
    justify-content: center !important;
}

.course-grid-container .mat-chip-list-wrapper {
    justify-content: center;
}

.alert {
    width: auto;
    height: auto;
    border-radius: 12px;
    font-weight: 400;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    // margin-top: 1rem;
    border: 1px solid #d2d2d7;
    border-color: rgba(227,0,0,.4);
    background-color: #fff2f4;
}