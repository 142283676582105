@use '@angular/material' as mat;

$uiuc-navy: #002855;
$uiuc-navy-lite: #002855db;

$primary-color: $uiuc-navy;

$uiuc-mcs-palette: (
 50: #e5eaf0,
 100: #bec9db,
 200: #94a6c3,
 300: #6b84ab,
 400: #496b9b, 
 500: #22528d,
 600: #1a4b84, 
 700: #0f4179,
 800: #06386c,
 900: #002855,
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white,
 )
);

$my-app-primary: mat.define-palette($uiuc-mcs-palette);
$my-app-accent:  mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

$small-breakpoint-width: 840px;
$phone-breakpoint: 480px;
$tablet-breakpoint: 800px;

$bp-xsmall: 320px;
$bp-teeny: 480px;
$bp-tiny: 600px;
$bp-small: 650px;
$bp-medium: 800px;
$bp-big: 1000px;

$main-pad-above: 80px;
$main-pad-above-mobile: 72px;
$main-pad-side: 2rem;
$main-pad-side-mobile: 8px;

// COLOR PALETTE
$blue: #1976d2;
$accentblue: #1e88e5;
$white: #ffffff;
$offwhite: #fafafa;
$mediumgray: #6e6e6e;
$darkgray: #444444;
$deepgray: #333333;

// // STATE COLORS
$focus-outline-ondark: rgba($white, 0.8);
$focus-outline-onlight: $accentblue;
